import React, { Component } from "react";
import PropTypes from "prop-types";

import "./InletBar.scss";

export class InletBar extends Component {
	render () {
		const { inlet } = this.props;

		const iconColor = "#FFF";
		const iconBgColor = "#6686ff";

		return (
			<div className="pg-inlet-bar">
				<div className="start">
					<div
						className="pg-inlet-bar-icon" style={{
						backgroundColor: iconBgColor,
						borderColor: iconBgColor,
						color: iconColor
					}}
					>
            <span className="icon">
              <i className="fa fa-database"/>
            </span>
					</div>
					<div className="pg-inlet-bar-label">
						<span>{inlet.name}</span>
					</div>
				</div>
			</div>
		);
	}
}

InletBar.propTypes = {
	playgroundProps: PropTypes.object.isRequired,
	inlet: PropTypes.object.isRequired
};

export default InletBar;
