import React from "react";

import InputField from "core/components/common/form/InputField";
import * as Yup from "yup";

export const getBasicInitialValues = (model) => {
	return {
		name: model.name || "",
		enabled: model.enabled || false
	};
};

export const getBasicYupSchema = () => {
	return {
		name: Yup.string()
			.required("Name is required")
	};
};

const BasicFields = ({ values, ...props }) => {
	return (
		<>
			<InputField
				key="name" name="name" label="Name" values={values}
				type="text" placeholder="Filter / canvas name"
				{...props}
			/>
			<InputField
				key="enabled" name="enabled" label="Enabled" values={values}
				type="bool" placeholder="Enabled" isSimplified
				{...props}
			/>
		</>
	);
};

export default BasicFields;
