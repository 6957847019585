import React, { Component } from "react";
import PropTypes from "prop-types";

import { getNamespace } from "core/lib/auth";

import "./GroupActions.scss";

const GroupAction = ({ icon, onClick }) => {
	return (
		<div className="action" onClick={onClick}>
      <span className="icon">
        <i className={icon}/>
      </span>
		</div>
	);
};

export class GroupActions extends Component {
	render () {
		const {
			group, openNewPlaygroundModal, openEditPlaygroundGroupModal, deletePlaygroundGroup,
			open, setOpen
		} = this.props;

		const handleEditClick = () => {
			openEditPlaygroundGroupModal(group);
		};
		const handleDeleteClick = () => {
			deletePlaygroundGroup({
				groupId: group.id,
				namespace: getNamespace()
			});
		};
		const handleNewClick = () => {
			openNewPlaygroundModal({
				group_id: group.id
			});
		};

		return (
			<div className="pg-group-actions">
				{group.id && <GroupAction onClick={handleEditClick} icon="fas fa-cog"/>}
				{group.id && <GroupAction onClick={handleDeleteClick} icon="fas fa-trash"/>}
				<GroupAction onClick={handleNewClick} icon="fas fa-plus"/>

				{open
					? (
						<GroupAction key="window-minimize" icon="fas fa-window-minimize"
									 onClick={() => setOpen(false)}/>
					)
					: (
						<GroupAction key="window-restore" icon="fas fa-window-restore" onClick={() => setOpen(true)}/>
					)}
			</div>
		);
	}
}

GroupActions.propTypes = {
	group: PropTypes.object.isRequired,
	openNewPlaygroundModal: PropTypes.func.isRequired,
	openEditPlaygroundGroupModal: PropTypes.func.isRequired,
	deletePlaygroundGroup: PropTypes.func.isRequired,
	open: PropTypes.bool,
	setOpen: PropTypes.func.isRequired
};

export default GroupActions;
