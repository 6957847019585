import { BasePortModel } from "./BasePortModel";
import { SuccessArrowLinkModel } from "../link/SuccessArrowLinkModel";

export class SuccessPortModel extends BasePortModel {
	constructor (alignment, isIn) {
		super({
			type: "success",
			name: alignment,
			alignment: alignment,
			in: isIn
		});
	}

	createLinkModel () {
		if (this.getOptions().in) {
			return null;
		}

		return new SuccessArrowLinkModel();
	}

	canLinkToPort (port) {
		if (super.canLinkToPort(port)) {
			if (port instanceof SuccessPortModel) {
				return true;
			}
		}

		return false;
	}
}
