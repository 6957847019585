import React, { Component } from "react";
import PropTypes from "prop-types";

import Inlet from "./Inlet";
import InletNewAction from "./InletNewAction";

export class Inlets extends Component {
	render () {
		const { playgroundProps } = this.props;
		const { dashboard } = playgroundProps;
		const { playground } = dashboard;

		if (!playground || !playground.schema || !playground.schema.inlets || playground.schema.inlets.length < 1) {
			return (
				<InletNewAction
					playgroundProps={playgroundProps}
					inletCount={0}
				/>
			);
		}

		return (
			<>
				<div className="pg-inlets">
					{playground.schema.inlets.map((inlet, i) =>
						<Inlet
							key={`pg-inlet-${i}`} playgroundProps={playgroundProps}
							inlet={inlet} index={i} count={playground.schema.inlets.length}
						/>
					)}
				</div>
				<InletNewAction
					playgroundProps={playgroundProps}
					inletCount={playground.schema.inlets.length}
				/>
			</>
		);
	}
}

Inlets.propTypes = {
	playgroundProps: PropTypes.object.isRequired
};

export default Inlets;
