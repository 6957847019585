import React from "react";

import { DefaultLinkFactory } from "@projectstorm/react-diagrams";

import { ArrowLinkWidget } from "./ArrowLinkWidget";

import "./ArrowLink.scss";

export class ArrowLinkFactory extends DefaultLinkFactory {
	generateReactWidget (event) {
		return <ArrowLinkWidget link={event.model} diagramEngine={this.engine}/>;
	}

	generateLinkSegment (model, selected, path) {
		return (
			<path
				className={"arrow-link-segment " + (selected ? "selected" : "")}
				stroke={selected ? model.getOptions().selectedColor : model.getOptions().color}
				strokeWidth={model.getOptions().width}
				d={path}
			/>
		);
	}
}
