// eslint-disable-next-line no-redeclare
/* global localStorage, fetch */

import { getContext } from "redux-saga/effects";
import { fromJS } from "immutable";

import { getAccountKey } from "../lib/auth";
import { environment } from "../../generated/env";

function getUrl (endpoint) {
	const baseUrl = environment.api;
	if (baseUrl.startsWith("http://") || baseUrl.startsWith("https://")) {
		return new URL(baseUrl + endpoint);
	} else {
		const { protocol, host } = window.location;
		return new URL(`${protocol}//${host}${baseUrl}${endpoint}`);
	}
}

export function * apiFetch (opts = fromJS({})) {
	const doLogout = yield getContext("doLogout");
	const url = getUrl(opts.endpoint);

	const fetchOpts = {
		method: opts.method || "GET",
		headers: opts.headers || {},
		body: opts.body
	};

	if (!opts.multiPart) {
		fetchOpts.headers["Content-Type"] = "application/json";
	}

	if (opts.authenticated) {
		const token = localStorage.getItem("token");
		if (token) {
			fetchOpts.headers.Authorization = `Bearer ${token}`;
		} else {
			throw new Error("No token saved!");
		}

		if (!opts.withoutAccount) {
			const accountKey = getAccountKey();
			if (accountKey) {
				fetchOpts.headers["UP-Account-Key"] = accountKey;
			}
		}
	}

	if (opts.params) {
		Object.keys(opts.params).forEach(key => url.searchParams.append(key, opts.params[key]));
	}

	return yield fetch(url, fetchOpts)
		.then(function (response) {
				if (response.status === 401) {
					doLogout();
					return;
				}

				if (response.status === 504) {
					// Gateway time-out (when the API is down).
					throw new Error("Failed to fetch from API: Gateway Time-Out");
				}

				// Include redirections as errors as we aren't expecting them.
				if (response.status >= 300) {
					return response.json()
						.then(function (json) {
								if (json) {
									const err = new Error(json.message);

									Object.keys(json).forEach(k => {
										err[k] = json[k];
									});

									throw err;
								} else {
									throw new Error(response.statusText || "Failed to fetch from API");
								}
							},
							// error function
							function (err) {
								// JSON parse error (ignore)
								console.error("Failed to fetch from API or parse response", err);

								throw new Error("Failed to fetch from API or parse response");
							});
				}

				return response.json()
					.then(json => ({ json, response }));
			},
			// error function
			function (response) {
				throw new Error(response.statusText || "Failed to fetch from API");
			});
}
