import React, { useState } from "react";
import PropTypes from "prop-types";

import Tabs, { Tab, TabLayoutDrawer } from "core/components/common/tabs/Tabs";

import PlaygroundDebuggerExecForm from "./exec/PlaygroundDebuggerExecForm";
import PlaygroundDebuggerTraceForm, { TraceTabIdResult } from "./trace/PlaygroundDebuggerTraceForm";

import "./PlaygroundDebuggerContent.scss";
import PlaygroundDebuggerQueryForm, { QueryTabIdResult } from "./query/PlaygroundDebuggerQueryForm";

const tabIdExec = "exec";
const tabIdTrace = "trace";
const tabIdQuery = "query";

export const PlaygroundDebuggerContent = ({
	model, dashboard, environments, onExecSubmit, resetExec, onTraceSubmit, resetTrace, onQuerySubmit, resetQuery,
	onKillSubmit, buttonFontColor, buttonBgColor, closeModal
}) => {
	if (!model) {
		model = {};
	}

	const [activeTab, setActiveTab] = useState(null);
	const [activeTraceTab, setActiveTraceTab] = useState(null);

	const { playground } = dashboard;

	const handleExec = (form) => {
		const { environment, ...model } = form;

		// TODO: This should be the selected version.
		//       (the backend code will need to be modified to allow others as well).
		model.version = "head";

		return onExecSubmit({
			namespace: playground.namespace,
			playgroundId: playground.playground_id,
			environment: environment,
			model: model
		});
	};

	const handleTrace = (form) => {
		setActiveTab(tabIdTrace);
		setActiveTraceTab(TraceTabIdResult);

		return onTraceSubmit({
			namespace: playground.namespace,
			playgroundId: playground.playground_id,
			...form
		});
	};

	const handleQuery = (form) => {
		setActiveTab(tabIdQuery);
		setActiveTraceTab(QueryTabIdResult);

		return onQuerySubmit({
			namespace: playground.namespace,
			playgroundId: playground.playground_id,
			...form
		});
	};

	const handleKill = (form, onSuccess) => {
		return onKillSubmit({
			namespace: playground.namespace,
			playgroundId: playground.playground_id,
			onSuccess: onSuccess,
			...form
		});
	};

	return (
		<div className="up-form with-tabs">
			<section className="modal-card-body pg-debugger-content">
				<Tabs
					layout={TabLayoutDrawer}
					borderColor={buttonBgColor}
					minWidth={120}
					onTabSelect={setActiveTab}
					selectedTab={activeTab}
				>
					<Tab
						id={tabIdExec}
						title="Run"
						icon="fad fa-running"
						isNested
					>
						<PlaygroundDebuggerExecForm
							model={model.exec || {}}
							dashboard={dashboard || {}}
							environments={environments || {}}
							onSubmit={handleExec}
							handleTrace={handleTrace}
							reset={resetExec}
							buttonBgColor={buttonBgColor}
							buttonFontColor={buttonFontColor}
						/>
					</Tab>
					<Tab
						id={tabIdTrace}
						title="Trace"
						icon="fad fa-route"
						isNested
					>
						<PlaygroundDebuggerTraceForm
							model={model.trace || {}}
							dashboard={dashboard || {}}
							onSubmit={handleTrace}
							reset={resetTrace}
							handleKill={handleKill}
							buttonBgColor={buttonBgColor}
							buttonFontColor={buttonFontColor}
							activeTab={activeTraceTab}
							setActiveTab={setActiveTraceTab}
						/>
					</Tab>
					<Tab
						id={tabIdQuery}
						title="Search"
						icon="fad fa-search"
						isNested
					>
						<PlaygroundDebuggerQueryForm
							model={model.query || {}}
							dashboard={dashboard || {}}
							environments={environments || {}}
							onSubmit={handleQuery}
							handleTrace={handleTrace}
							reset={resetQuery}
							handleKill={handleKill}
							buttonBgColor={buttonBgColor}
							buttonFontColor={buttonFontColor}
							activeTab={activeTraceTab}
							setActiveTab={setActiveTraceTab}
						/>
					</Tab>
				</Tabs>
			</section>
			<footer className="modal-card-foot">
				<button type="submit" className="button" onClick={closeModal}>Close</button>
			</footer>
		</div>
	);
};

PlaygroundDebuggerContent.propTypes = {
	model: PropTypes.object.isRequired,
	dashboard: PropTypes.object.isRequired,
	environments: PropTypes.object.isRequired,
	onExecSubmit: PropTypes.func.isRequired,
	resetExec: PropTypes.func.isRequired,
	onTraceSubmit: PropTypes.func.isRequired,
	resetTrace: PropTypes.func.isRequired,
	onQuerySubmit: PropTypes.func.isRequired,
	resetQuery: PropTypes.func.isRequired,
	onKillSubmit: PropTypes.func.isRequired,
	buttonBgColor: PropTypes.string.isRequired,
	buttonFontColor: PropTypes.string.isRequired,
	closeModal: PropTypes.func.isRequired
};

export default PlaygroundDebuggerContent;
