import { ArrowLinkModel } from "./ArrowLinkModel";
import config from "../../../../config";
import { CfgColors } from "core/config";

export class SuccessArrowLinkModel extends ArrowLinkModel {
	constructor () {
		const colors = config.get(CfgColors);

		super("success", colors.green, colors.green);
	}
}
