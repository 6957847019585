import React, { Component } from "react";
import { connect } from "react-redux";

import config from "../../config";
import { CfgColors } from "core/config";

import Modal from "core/components/common/modal/Modal";
import {
	ClosePlaygroundDebuggerModalAction,
	PlaygroundDebuggerExecAction,
	PlaygroundDebuggerExecResetAction,
	PlaygroundDebuggerQueryAction,
	PlaygroundDebuggerQueryResetAction,
	PlaygroundDebuggerTraceAction,
	PlaygroundDebuggerTraceResetAction
} from "../../actions/playground/debugger";
import { PlaygroundDebuggerContent } from "../../components/playground/debugger/PlaygroundDebuggerContent";
import { PlaygroundKillAction } from "../../actions/playground/kill";

class PlaygroundDebuggerModal extends Component {
	render () {
		let {
			onExecSubmit, resetExec, onTraceSubmit, resetTrace, onQuerySubmit, resetQuery, onKillSubmit,
			closeModal, playgroundDebugger, playgroundDashboard, environments
		} = this.props;
		if (!playgroundDebugger) {
			playgroundDebugger = {};
		}
		if (!playgroundDashboard) {
			playgroundDashboard = {};
		}
		if (!environments) {
			environments = {};
		}

		const open = !!(playgroundDebugger && playgroundDebugger.open);
		if (!open) {
			return null;
		}

		const colourCfg = config.get(CfgColors);

		return (
			<>
				<Modal
					title="Debugger"
					closeModal={closeModal}
					icon="fas fa-debug"
					modalState={open}
					headBgColor={colourCfg.green}
					fontColor={colourCfg.white}
					width="calc(100vw - 100px)"
				>
					<PlaygroundDebuggerContent
						model={playgroundDebugger}
						dashboard={playgroundDashboard}
						environments={environments.environments || {}}
						onExecSubmit={onExecSubmit}
						resetExec={resetExec}
						onTraceSubmit={onTraceSubmit}
						resetTrace={resetTrace}
						onQuerySubmit={onQuerySubmit}
						resetQuery={resetQuery}
						onKillSubmit={onKillSubmit}
						buttonFontColor={colourCfg.white}
						buttonBgColor={colourCfg.green}
						closeModal={closeModal}
					/>
				</Modal>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		onExecSubmit: (payload) => dispatch(PlaygroundDebuggerExecAction(payload)),
		resetExec: () => dispatch(PlaygroundDebuggerExecResetAction()),
		onTraceSubmit: (payload) => dispatch(PlaygroundDebuggerTraceAction(payload)),
		resetTrace: () => dispatch(PlaygroundDebuggerTraceResetAction()),
		onQuerySubmit: (payload) => dispatch(PlaygroundDebuggerQueryAction(payload)),
		resetQuery: () => dispatch(PlaygroundDebuggerQueryResetAction()),
		onKillSubmit: (payload) => dispatch(PlaygroundKillAction(payload)),
		closeModal: () => dispatch(ClosePlaygroundDebuggerModalAction())
	};
};

const mapStateToProps = (state, props) => {
	const core = state.get("core");
	const data = core.get("data");
	const environment = data.get("environment");
	const variable = environment.get("variable");

	const canvas = state.get("canvas");
	if (!canvas) {
		return {};
	}
	const playground = canvas.get("playground");
	if (!playground) {
		return {};
	}

	return {
		playgroundDebugger: playground.get("debugger"),
		playgroundDashboard: playground.get("dashboard"),
		environments: variable.get("query")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaygroundDebuggerModal);
