import React from "react";

import "./MessageDetails.scss";

const systemErrorMessage = "An internal system error occured, please try again or contact support";

export const MessageDetail = ({ title, value }) => {
	if (!value) {
		return "";
	}

	return (
		<div className="message-detail">
			<div className="detail-title">
				{title}
			</div>
			<div className="detail-value">
				{value}
			</div>
		</div>
	);
};

export const MessageDetails = ({ details }) => {
	return (
		<div className="message-details">
			{details.map(d => {
				return <MessageDetail key={d.title} title={d.title} value={d.value}/>;
			})}
		</div>
	);
};

export const ErrorMessageDetails = ({ error, showMessage, showGeneric }) => {
	return (
		<div className="message-details">
			<MessageDetail title="Correlation Id" value={error.correlation_id}/>
			<MessageDetail title="Error Code" value={error.error_code}/>
			{(showGeneric || (showMessage && error.message)) && (
				<MessageDetail title="Message"
							   value={showMessage && error.message ? error.message : systemErrorMessage}/>
			)}
			{showMessage && (
				<MessageDetail title="Details" value={error.details}/>
			)}
		</div>
	);
};
