import React from "react";
import Stub from "../../components/common/Stub/Stub";
import BigSelector, {BigSelectorItem} from "../../components/common/BigSelector/BigSelector";
import {useHistory} from "react-router-dom";


export function AccountSettings() {

    const history = useHistory();

    const items: BigSelectorItem[] = [
        {
            name: "Environments",
            id: "accountEnvironments",
            icon: "fas fa-cubes",
            onActivate: () => history.push("/account/environments")
        },
        {
            name: "Prototypes",
            id: "prototypes",
            icon: "fas fa-puzzle-piece",
            onActivate: () => history.push("/prototypes")
        },
        {
            name: "Payment and Billing",
            id: "payment",
            icon: "fas fa-credit-card",
        },
        {
            name: "Support",
            id: "support",
            icon: "fas fa-life-ring",
        },
        {
            name: "Logout",
            id: "logout",
            icon: <i className="warning fas fa-sign-out-alt"/>,
            onActivate: () => {
                history.push("/logout");
            },
        }
    ];

    return (
        <>
            <Stub/>
            <BigSelector title="Account Settings" items={items}/>
        </>
    );
}

export default AccountSettings;
