import cloneDeep from "lodash/cloneDeep";

import config, { CfgNodeEdit } from "../";

import {
	FieldTypeSelect,
	FieldTypeText,
	FieldTypeTextArea,
	ValidationOneFieldRequired
} from "core/components/common/form";
import { NodeTypeModule, NodeTypeSMS, NodeTypeTimer, NodeTypeVoice } from "./";
import { nameToCapUnderScoreSlugKey } from "core/lib/variable/keys";

// Defaults

const defaultConfig = {};

defaultConfig[NodeTypeModule] = {
	form: {
		sections: [
			{
				key: "BASIC",
				label: "Basic",
				position: 10,
				panels: [
					{
						fields: [
							{
								name: "name",
								type: FieldTypeText,
								label: "Name for this step",
								placeholder: "Type a name for this step",
								position: 10
							}
						]
					}
				]
			}
		]
	}
};

defaultConfig[NodeTypeVoice] = {
	form: {
		layout: "drawer",
		sections: [
			{
				key: "BASIC",
				label: "Basic",
				icon: "fas fa-file-alt",
				position: 10,
				panels: [
					{
						fields: [
							{
								name: "name",
								type: FieldTypeText,
								label: "Name for this step",
								placeholder: "Type a name for this step",
								position: 10
							}, {
								name: "caller_id",
								type: FieldTypeText,
								label: "Phone number to use",
								placeholder: "Type a number",
								position: 20
							}, {
								name: "templateId",
								type: FieldTypeSelect,
								label: "Template",
								placeholder: "Use Raw Message",
								emptyPlaceholder: "No templates available",
								position: 30
							}
						]
					}
				]
			}, {
				key: "ADVANCED",
				label: "Advanced",
				icon: "fas fa-file-code",
				position: 20,
				panels: [
					{
						fields: [
							{
								name: "example",
								type: FieldTypeText,
								label: "Example for advanced",
								placeholder: "Type a word here",
								position: 10
							}
						]
					}
				]
			}
		]
	}
};

defaultConfig[NodeTypeSMS] = {
	form: {
		sections: [
			{
				key: "BASIC",
				label: "Basic",
				position: 10,
				panels: [
					{
						fields: [
							{
								name: "name",
								type: FieldTypeText,
								label: "Name for this step",
								placeholder: "Type a name for this step",
								position: 10
							}, {
								name: "caller_id",
								type: FieldTypeText,
								label: "Phone number to use",
								placeholder: "Type a number",
								position: 20
							}
						]
					}
				]
			}, {
				label: "Content",
				position: 20,
				panels: [
					{
						fields: [
							{
								name: "templateId",
								type: FieldTypeSelect,
								label: "Template",
								placeholder: "Use Raw Message",
								position: 10,
								options: [
									{ value: "1234", label: "Template 1" },
									{ value: "8f8eeb57-7699-1705-1a0a-0594dcbc7772", label: "Template 2" },
									{ value: "3433", label: "Template 3" }
								]
							}, {
								name: "rawMessage",
								type: FieldTypeTextArea,
								label: "Raw Message",
								placeholder: "Type raw message",
								emptyPlaceholder: "No templates available",
								position: 20,
								disabledBy: ["templateId"]
							}
						]
					}
				]
			}
		],
		validations: [
			{
				type: ValidationOneFieldRequired,
				fields: ["templateId", "rawMessage"],
				message: "Please select a template or enter a raw message"
			}
		]
	}
};

defaultConfig[NodeTypeTimer] = {
	form: {
		sections: [
			{
				key: "BASIC",
				label: "Basic",
				position: 10,
				panels: [
					{
						fields: [
							{
								name: "name",
								type: FieldTypeText,
								label: "Name for this step",
								placeholder: "Type a name for this step",
								position: 10
							}, {
								name: "delay",
								type: FieldTypeText,
								label: "Delay",
								placeholder: "Type a time delay (i.e. 3m40s)",
								position: 20
							}
						]
					}
				]
			}
		]
	}
};

export const getNodeTypeEditSchema = (nodeType, opts) => {
	const nodeEditCfg = config.get(CfgNodeEdit);

	let cfgOpts = {};
	if (nodeEditCfg[nodeType]) {
		cfgOpts = nodeEditCfg[nodeType];
	}

	let defaultOpts = {};
	if (defaultConfig[nodeType]) {
		defaultOpts = defaultConfig[nodeType];
	} else if (defaultConfig[NodeTypeModule]) {
		defaultOpts = defaultConfig[NodeTypeModule];
	}

	return mergeNodeTypeEditSchema(
		mergeNodeTypeEditSchema(defaultOpts, cfgOpts), opts
	);
};

export const mergeNodeTypeEditSchema = (opts, inOpts) => {
	const ourOpts = prepareInputEditSchema(opts);
	let applyOpts = prepareInputEditSchema(inOpts);

	if (!applyOpts) {
		applyOpts = {};
	}

	// Fairly rustic merging (only section level atm).
	if (ourOpts.form.sections && applyOpts.form.sections) {
		applyOpts.form.sections = mergeKeyedArray(ourOpts.form.sections,
			applyOpts.form.sections);
	} else if (ourOpts.form.sections) {
		applyOpts.form.sections = ourOpts.form.sections;
	}

	return {
		...ourOpts,
		...applyOpts,
		form: {
			// Rustic merge of top level for form.
			...ourOpts.form,
			...applyOpts.form
		}
	};
};

export const prepareInputEditSchema = (inSchema) => {
	if (!inSchema) {
		inSchema = {};
	}

	const schema = cloneDeep(inSchema);
	if (schema.form) {
		if (schema.form.sections) {
			for (let si = 0; si < schema.form.sections.length; si++) {
				const section = schema.form.sections[si];
				if (!section.key) {
					if (section.label) {
						section.key = nameToCapUnderScoreSlugKey(section.label);
					}
				}

				schema.form.sections[si] = section;
			}
		}
	} else {
		schema.form = {};
	}

	return schema;
};

export const mergeKeyedArray = (a, b) => {
	const strippedA = [];
	const overrideKeys = [];

	b.forEach(it => {
		overrideKeys.push(it.key);
	});

	a.forEach(it => {
		if (!overrideKeys.includes(it.key)) {
			strippedA.push(it);
		}
	});

	return [
		...strippedA,
		...b
	];
};
