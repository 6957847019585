import {DispatchApi} from "../../core/dispatch/dispatch";

export type NumberDescriptor = {
    $id: number;
    number: string;
    country: string;
    isActive: boolean;
    isMobile: boolean;
    isSMS: boolean;
    isVoice: boolean;
    smsTemplateId: string | null;
    voiceTemplateId: string | null;
    namespace: string;
    modified: number;
    name: string;
}

export class NumbersDispatchApi extends DispatchApi {

    constructor(token: string | undefined | null) {
        super(token, "numbers");
    }

    async getNumbers(): Promise<NumberDescriptor[]> {
        const list = await this.dispatch<NumberDescriptor[]>("list");
        return list.sort(a => a.modified).reverse();
    }
}
