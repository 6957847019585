import cloneDeep from "lodash/cloneDeep";

import { CfgIconStyles, spriteMap, sprites } from "./";

export const getTitleBgColor = (titleBgColors, index) => {
	if (index <= titleBgColors.length - 1) {
		return titleBgColors[index];
	}

	return titleBgColors[(index % titleBgColors.length - 1) + 1];
};

export const getSpriteKeyMap = () => {
	const spriteMap = {};

	for (const spriteType in sprites) {
		if (Object.prototype.hasOwnProperty.call(sprites, spriteType)) {
			if (sprites[spriteType].symbol_ids) {
				sprites[spriteType].symbol_ids.forEach(id => {
					spriteMap[id] = spriteType;
				});
			}
		}
	}

	return spriteMap;
};

export const getSvgSpriteStyles = (config, id) => {
	const iconStyles = config.get(CfgIconStyles);

	id = id.replaceAll(/^#/g, "");

	let styles = {
		sprite: "#" + id
	};

	if (spriteMap[id]) {
		styles.set = spriteMap[id];
	}

	if (iconStyles) {
		if (styles.set) {
			if (iconStyles.set) {
				iconStyles.set.forEach(s => {
					if (s.sets && s.sets.includes(styles.set)) {
						if (s.styles) {
							styles = {
								...styles,
								...cloneDeep(s.styles)
							};
						}
					}
				});
			}
		}

		if (iconStyles.sprite) {
			iconStyles.sprite.forEach(s => {
				if (s.sprites && s.sprites.includes(id)) {
					if (s.styles) {
						styles = {
							...styles,
							...cloneDeep(s.styles)
						};
					}
				}
			});
		}
	}

	return styles;
};
