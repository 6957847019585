import { PortModelAlignment } from "@projectstorm/react-diagrams";

import config, { CfgDiagram } from "../../../../config";
import { buildNode } from "./nodeFactory";
import { nearestToNumber } from "core/lib/math";
import { NodeModelTypeSmall, NodeModelTypeTarget } from "../../../../config/node";
import { BranchingOutcomeFailure, BranchingOutcomeSuccess } from "../../../../lib/playground/constants";
import {
	calculateOffsetY,
	calculateTargets,
	positionChangeNodeListener,
	TargetKeyChildren,
	updateStateBoundsFromNode
} from "./utils";
import { getNodeBranchingOutcomes } from "../../../../config/node/util";

const StartNodeId = "start";

const diagramCfg = config.get(CfgDiagram);

export const generateModel = (model, data, playgroundProps) => {
	// Validate data
	validateData(data);

	// Init
	const state = {
		models: {},
		targets: {},
		maxY: 0,
		minY: 0,
		maxX: 0,
		minX: 0
	};

	// Traverse
	const opts = {
		model: model,
		data: data,
		state: state,
		playgroundProps: playgroundProps
	};

	// Calculate Targets
	calculateTargets(opts, data);

	// Generate Nodes
	nodeWalker({
		...opts,
		targetKeys: [TargetKeyChildren]
	}, generateNode);

	// Generate Links
	nodeWalker({
		...opts,
		targetKeys: [TargetKeyChildren]
	}, generateLink);

	model.setOffsetY(calculateOffsetY(state.minY));

	return state;
};

/// / Walker

export const nodeWalker = (opts, cb, nodeId, parentNodeId, linkData) => {
	const { data, state, targetKeys } = opts;

	if (!nodeId || nodeId === StartNodeId) {
		cb(opts, StartNodeId, data.start);

		if (data.start && data.start.goto && data.start.goto.target) {
			return nodeWalker(opts, cb, data.start.goto.target, StartNodeId, {
				type: BranchingOutcomeSuccess,
				seq: 0,
				count: 1
			});
		} else {
			return generateSetTargetNode(opts, cb, StartNodeId, {
				type: BranchingOutcomeSuccess,
				seq: 0,
				count: 1,
				firstNode: true
			});
		}
	}

	if (!data.nodes || !data.nodes[nodeId]) {
		throw new Error("Failed to find node data for nodeId '" + nodeId + "' in data");
	}

	const nodeData = data.nodes[nodeId];

	validateNodeData(nodeData, nodeId);

	// Process

	const targets = state.targets[nodeId];

	cb(opts, nodeId, nodeData, parentNodeId, {
		targets: targets,
		...linkData
	});

	// Traverse
	getNodeBranchingOutcomes(opts)
		.filter(outcome => {
			return targets[outcome];
		})
		.forEach(outcome => {
			targetKeys.forEach(targetKey => {
				if (targets[outcome][targetKey]) {
					const branchCount = targets[outcome][targetKey].length;
					for (let i = 0; i < branchCount; i++) {
						const route = targets[outcome][targetKey][i];

						validateGotoRoute(route, "in " + nodeId + "." + outcome + "[" + i + "]");

						nodeWalker(opts, cb, route.goto.target, nodeId, {
							type: outcome,
							seq: i,
							count: branchCount
						});
					}
				}
			});
		});
};

/// Nodes

export const generateNode = (opts, nodeId, nodeData, parentNodeId, linkData) => {
	const { data, model, state, playgroundProps } = opts;
	const layout = (data.layout) ? data.layout : {};
	const modelsLayout = (layout.models) ? layout.models : {};

	if (state.models[nodeId]) {
		// Already rendered
		return;
	}

	if (nodeId === StartNodeId) {
		return generateStartNode(opts, nodeId, nodeData);
	}

	const { type, seq, count } = linkData;

	const parentModel = state.models[parentNodeId];
	let [parentX, parentY] = parentModel.getCentrePosition();
	const parentModelType = parentModel.getOptions().modelType;

	const buildOpts = {};
	if (nodeData.type === NodeModelTypeTarget) {
		buildOpts.modelType = NodeModelTypeTarget;
	} else {
		if (parentModelType === NodeModelTypeSmall) {
			buildOpts.modelType = NodeModelTypeSmall;
		}
	}

	if (count > 1) {
		buildOpts.modelType = NodeModelTypeSmall;
	}

	const node = buildNode(nodeId, nodeData, parentNodeId, {
		...buildOpts,
		teleports: state.teleports,
		linkData: linkData
	}, data, playgroundProps);

	let canSetPosition = true;
	const nodeSpacing = (diagramCfg.gridSize * diagramCfg.nodeSpacingMultiplier);
	if (parentNodeId === StartNodeId) {
		// Adjust parentX (start line is shorter)
		parentX -= (nodeSpacing - (diagramCfg.gridSize * 7));

		canSetPosition = false;
	} else if (nodeData.type === NodeModelTypeTarget) {
		// Adjust parentX/parentY (slightly longer than start)
		if (type === BranchingOutcomeSuccess) {
			parentX -= (nodeSpacing - (diagramCfg.gridSize * 9));
		} else {
			parentY -= (nodeSpacing - (diagramCfg.gridSize * 9));
		}

		canSetPosition = false;
	}

	if (canSetPosition && (modelsLayout[nodeId] && modelsLayout[nodeId].position)) {
		const [x, y] = modelsLayout[nodeId].position;
		node.setCentrePosition(x, y);
	} else {
		switch (type) {
			case BranchingOutcomeSuccess:
				node.setCentrePosition(calculateNodePoint(parentX), calculateNodeSplayingPoint(parentY, seq, count));

				break;
			case BranchingOutcomeFailure:
				node.setCentrePosition(calculateNodeSplayingPoint(parentX, seq, count), calculateNodePoint(parentY));

				break;
			default:
		}
	}

	if (canSetPosition) {
		node.setLocked(false);

		node.registerListener(
			positionChangeNodeListener(node, playgroundProps)
		);
	} else {
		node.setLocked(true);
	}

	state.models[node.getID()] = node;

	// Update bounds.
	updateStateBoundsFromNode(state, node);

	model.addAll(node);
};

export const generateStartNode = (opts, nodeId, nodeData) => {
	const { model, state } = opts;

	if (!nodeId) {
		nodeId = StartNodeId;
	}

	const startNode = buildNode(nodeId, {
		type: StartNodeId,
		teleports: state.teleports,
		...nodeData
	}, null, null, null, opts.playgroundProps);

	startNode.setCentrePosition(diagramCfg.gridSize * diagramCfg.startXMultiplier,
		diagramCfg.gridSize * diagramCfg.startYMultiplier);
	startNode.setLocked(true);

	state.models[startNode.getID()] = startNode;

	// Update bounds.
	updateStateBoundsFromNode(state, startNode);

	model.addAll(startNode);
};

export const generateSetTargetNode = (opts, cb, parentNodeId, linkData) => {
	const { type } = linkData;
	const nodeId = parentNodeId + "-set-" + type;

	return cb(opts, nodeId, {
		type: NodeModelTypeTarget
	}, parentNodeId, linkData);
};

/// Links

export const generateLink = (opts, nodeId, nodeData, parentNodeId, linkData) => {
	if (!nodeId || nodeId === StartNodeId) {
		// Skip
		return;
	}

	const { state, model } = opts;
	const { type } = linkData;
	const node = state.models[nodeId];
	if (!node) {
		throw new Error("generateLink: failed to find node model '" + nodeId + "'");
	}
	const parentNode = state.models[parentNodeId];
	if (!parentNode) {
		throw new Error("generateLink: failed to find parentNode model '" + parentNodeId + "'");
	}

	let link = null;
	switch (type) {
		case BranchingOutcomeSuccess:
			link = parentNode.getPort(PortModelAlignment.RIGHT).link(node.getPort(PortModelAlignment.LEFT));

			break;
		case BranchingOutcomeFailure:
			link = parentNode.getPort(PortModelAlignment.BOTTOM).link(node.getPort(PortModelAlignment.TOP));

			break;
		default:
	}

	if (link) {
		if (parentNodeId === StartNodeId) {
			link.setLocked(true);
		}
	}

	model.addAll(link);
};

/// / Positioning

export const calculateNodePoint = (startXY) => {
	return startXY + (diagramCfg.gridSize * diagramCfg.nodeSpacingMultiplier);
};

export const calculateNodeSplayingPoint = (startXY, seq, count) => {
	if (count && count > 1) {
		const nodeSpacing = diagramCfg.gridSize * diagramCfg.nodeSplayingMultiplier;
		const minFromEdge = diagramCfg.gridSize * diagramCfg.minFromEdgeMultiplier;

		const totalHeight = nodeSpacing * (count - 1);
		const newStartXY = nearestToNumber(startXY - (totalHeight / 2), diagramCfg.gridSize);

		if (newStartXY < minFromEdge) {
			// newStartXY = minFromEdge;
		}

		return nearestToNumber(newStartXY + (seq * nodeSpacing),
			diagramCfg.gridSize);
	}

	return startXY;
};

/// / Validation

export const validateData = (data) => {
	if (!data) {
		throw new Error("No data provided");
	}

	if (data.start && data.start.goto && data.start.goto.target) {
		validateGotoRoute(data.start, "in start");
	}
};

export const validateGotoRoute = (route, desc) => {
	if (!route) {
		throw new Error("No route provided (" + desc + ")");
	}
	if (!route.goto) {
		throw new Error("No 'goto' in route (" + desc + ")");
	}
	if (!route.goto.target && !route.goto.canvas && !route.goto.phase) {
		throw new Error("No 'goto.target', 'goto.canvas' or 'goto.phase' in route (" + desc + ")");
	}
};

export const validateEnumRoute = (route, desc) => {
	if (!route) {
		throw new Error("No route provided (" + desc + ")");
	}
	if (!route.branching) {
		throw new Error("No 'branching' in route (" + desc + ")");
	}
};

export const validateNodeData = (nodeData, nodeId) => {
	if (!nodeData) {
		throw new Error("No nodeData provided for nodeId: " + nodeId);
	}
	for (const key of ["type", "branching"]) {
		if (!nodeData[key]) {
			throw new Error("Missing key '" + key + "' in nodeData for nodeId: " + nodeId);
		}
	}
};
