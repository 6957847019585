import config from "../../../../config";
import { CfgColors } from "core/config";

import { deleteCanvasPhase } from "./update";

const colourCfg = config.get(CfgColors);

export const clickCanvasPhaseContextMenuAction = (
	playgroundProps, canvas, phase
) => {
	return (e) => {
		const { dashboard, openEditPhase, updateCanvas, openConfirm, showContextMenu } = playgroundProps;

		e.preventDefault();

		showContextMenu({
			actions: [{
				label: "Edit",
				icon: "fad fa-edit",
				onClick: () => {
					openEditPhase({ phase: phase, canvas: canvas });
				}
			}, {
				label: "Delete",
				icon: "fad fa-trash",
				onClick: () => {
					openConfirm({
						title: "Delete Phase?",
						confirmLabel: "Delete",
						headBgColor: colourCfg.red,
						fontColor: colourCfg.white,
						content: "Are you sure you want to delete this phase? This action is not reversible and all the nodes " +
							"within the phase will be lost.",
						onConfirm: () => {
							updateCanvas({
								namespace: canvas.namespace,
								playgroundId: canvas.playground_id,
								canvasId: canvas.canvas_id,
								model: deleteCanvasPhase(dashboard, canvas.canvas_id, phase.id)
							});
						}
					});
				}
			}],
			eventY: e.clientY,
			eventX: e.clientX,
			itemActiveColor: colourCfg.purple,
			itemActiveFontColor: colourCfg.white
		});
	};
};
