// eslint-disable-next-line no-redeclare
/* global localStorage */

import { call, put, take } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { TokenExpiredAction, TokenRefreshAction } from "../actions/auth";
import { tokenNeedsRefresh } from "../lib/auth/index";

function tokenRefreshInitChannel () {
	return eventChannel(emitter => {
		const iv = setInterval(() => {
			const token = localStorage.getItem("token");
			if (token) {
				const tokenExpiry = parseInt(localStorage.getItem("tokenExpiry"));
				const tokenStatus = tokenNeedsRefresh(token, tokenExpiry);

				if (tokenStatus > 0) {
					// Needs refresh
					return emitter(TokenRefreshAction());
				} else if (tokenStatus === -1) {
					// Expired
					return emitter(TokenExpiredAction());
				}
			}
		}, 60 * 1000); // Check every 1 minutes
		// The subscriber must return an unsubscribe function
		return () => {
			clearInterval(iv);
		};
	});
}

// Saga
function * tokenRefreshSaga () {
	const channel = yield call(tokenRefreshInitChannel);
	while (true) {
		const action = yield take(channel);
		switch (action.type) {
			case TokenRefreshAction.getType():
			case TokenExpiredAction.getType():
				yield put(action);
				break;
			default:
				console.log("Ignoring token refresh event", action);
		}
	}
}

export default tokenRefreshSaga;
