import React, { Component } from "react";
import PropTypes from "prop-types";

import "./CanvasActions.scss";

const CanvasAction = ({ icon, onClick }) => {
	return (
		<div className="action" onClick={onClick}>
      <span className="icon">
        <i className={icon}/>
      </span>
		</div>
	);
};

export class CanvasActions extends Component {
	render () {
		return (
			<div className="pg-canvas-actions">
				<CanvasAction icon="fas fa-cog"/>
				<CanvasAction icon="fas fa-window-restore"/>
			</div>
		);
	}
}

CanvasActions.propTypes = {
	playgroundProps: PropTypes.object.isRequired,
	canvas: PropTypes.object.isRequired,
	phase: PropTypes.object,
	setPhaseId: PropTypes.func.isRequired
};

export default CanvasActions;
