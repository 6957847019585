import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import Tabs, { ErrorSection, Tab } from "core/components/common/tabs/Tabs";
import Loading from "components/common/loading/Loading";
import InlineError from "../../common/message/InlineError";
import InputField from "../../common/form/InputField";
import {
	EnvironmentList,
	EnvironmentOptions,
	EnvironmentScopeAccount,
	EnvironmentScopeNamespace,
	EnvironmentScopeOptions,
	EnvironmentScopeRestrictedOptions,
	EnvironmentTest
} from "../../../lib/constants";
import { getEnvironmentGroupsFor } from "./Environments";
import { validateGroupName } from "./GroupSettings";
import { authHaveAnySuperOrRootRoles, authRequestedSuperOrRoot } from "../../../lib/auth";

const EnvironmentNewFormMessages = ({ error }) => {
	if (error) {
		return (
			<InlineError>
				<p>There was an error processing your request, please try again.</p>
			</InlineError>
		);
	}

	return "";
};

const newEnvironmentSchema = Yup.object().shape({
	env: Yup.string().required("Environment is required"),
	name: Yup.string()
		.required("Name is required")
		.test("NameValidation", "Name is invalid", function () {
			const err = validateGroupName(this.parent.env)(this.parent.name);
			if (err) {
				return this.createError({
					message: err,
					path: "name"
				});
			}

			return true;
		})
});

const extractDefaultNames = (environments, scope, inherits) => {
	const defaultNames = {};
	EnvironmentList.forEach(env => {
		const seenNames = {};
		let envCount = 0;
		getEnvironmentGroupsFor(env, environments, scope, inherits).forEach(e => {
			if (e.scope === scope) {
				envCount++;
				seenNames[e.name] = true;
			}
		});

		if (envCount < 1) {
			if (scope === EnvironmentScopeNamespace) {
				defaultNames[env] = env + "-global-default";
			} else {
				defaultNames[env] = env + "-default";
			}
		} else {
			envCount++;

			let curNameTry = env + "-" + envCount;
			if (scope === EnvironmentScopeNamespace) {
				curNameTry = env + "-global-" + envCount;
			}
			while (seenNames[curNameTry]) {
				envCount++;

				curNameTry = env + "-" + envCount;
				if (scope === EnvironmentScopeNamespace) {
					curNameTry = env + "-global-" + envCount;
				}
			}

			defaultNames[env] = curNameTry;
		}
	});

	return defaultNames;
};

export class EnvironmentNewForm extends Component {
	render () {
		let { model, environmentsModel, onSubmit, buttonBgColor, buttonFontColor } = this.props;
		if (!model) {
			model = {};
		}

		const loading = model.loading;
		const error = model.error;
		const environments = environmentsModel.environments || {};

		const inheritsForAccount = [EnvironmentScopeNamespace];

		const defaultScope = model.scope || EnvironmentScopeAccount;
		const scopeOptions = (authHaveAnySuperOrRootRoles() && authRequestedSuperOrRoot())
			? EnvironmentScopeRestrictedOptions
			: EnvironmentScopeOptions;

		const defaultEnv = model.env || EnvironmentTest;

		const defaultNames = {
			[EnvironmentScopeAccount]: extractDefaultNames(environments, EnvironmentScopeAccount, inheritsForAccount),
			[EnvironmentScopeNamespace]: extractDefaultNames(environments, EnvironmentScopeNamespace, [])
		};

		return (
			<Formik
				onSubmit={onSubmit}
				initialValues={{
					scope: defaultScope,
					env: defaultEnv,
					name: defaultNames[defaultScope][defaultEnv]
				}}
				validationSchema={newEnvironmentSchema}
			>
				{({ values, errors, touched, setFieldValue, ...props }) => (
					<Form className="up-form with-tabs">
						<section className="modal-card-body">
							{loading
								? <Loading/>
								: (
									<>
										<Tabs
											values={values} errors={errors} touched={touched}
											borderColor={buttonBgColor}
											setFieldValue={setFieldValue} {...props}
										>
											<ErrorSection>
												<EnvironmentNewFormMessages error={error}/>
											</ErrorSection>
											<Tab id="basic" title="Basic" fields={["name"]}>
												<InputField
													key="scope" name="scope" label="Scope"
													type="select" placeholder="Select scope" options={scopeOptions}
													values={values} setFieldValue={setFieldValue}
													{...props}
												/>
												<InputField
													key="env" name="env" label="Environment"
													type="select" placeholder="Select environment"
													options={EnvironmentOptions}
													values={values} setFieldValue={setFieldValue}
													onChange={(v) => {
														const newEnv = v.target.value;

														const curScope = values.scope;
														const curNames = defaultNames[curScope];

														let nameIsDefault = false;
														for (const v of Object.values(curNames)) {
															if (v === values.name) {
																nameIsDefault = true;
															}
														}

														if (nameIsDefault) {
															setFieldValue("name", curNames[newEnv], false);
														}
													}}
													{...props}
												/>
												<InputField
													key="name" name="name" label="Name"
													type="text" placeholder="Environment name"
													values={values} setFieldValue={setFieldValue}
													{...props}
												/>
											</Tab>
										</Tabs>
									</>
								)}
						</section>
						<footer className="modal-card-foot">
							<button
								type="submit" className="button is-primary" disabled={loading} style={{
								backgroundColor: buttonBgColor,
								color: buttonFontColor
							}}
							>Create
							</button>
						</footer>
					</Form>
				)}
			</Formik>
		);
	}
}

EnvironmentNewForm.propTypes = {
	model: PropTypes.object.isRequired,
	environmentsModel: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	buttonBgColor: PropTypes.string.isRequired,
	buttonFontColor: PropTypes.string.isRequired
};

export default EnvironmentNewForm;
