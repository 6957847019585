import React from "react";
import Stub from "../../components/common/Stub/Stub";

export const Dashboard = () => {
	return <>
		<Stub/>
		<h2>Dashboard</h2>
	</>;
};

export default Dashboard;
