import React, { Component } from "react";
import { connect } from "react-redux";

import config from "../../config";
import { CfgColors } from "core/config";
import Modal from "core/components/common/modal/Modal";
import PlaygroundGroupSettingsForm from "../../components/playground/group/PlaygroundGroupSettingsForm";
import { CloseNewPlaygroundGroupModalAction, PlaygroundGroupNewAction } from "../../actions/playground/group/new";
import { getNamespace } from "core/lib/auth";

class PlaygroundGroupNewModal extends Component {
	render () {
		let { closeModal, playgroundGroup, newPlaygroundGroup, playgroundList } = this.props;
		if (!playgroundGroup) {
			playgroundGroup = {};
		}
		let model = playgroundGroup.model;
		if (!model) {
			model = {};
		}
		const open = !!(playgroundGroup && playgroundGroup.open);
		if (!open) {
			return null;
		}

		if (playgroundGroup.loading) {
			closeModal = () => {
				// Disabled.
			};
		}

		const colourCfg = config.get(CfgColors);

		let curMaxPosition = 0;
		if (playgroundList && playgroundList.groups) {
			playgroundList.groups.forEach(g => {
				if (g.position > curMaxPosition) {
					curMaxPosition = g.position;
				}
			});
		}

		const onSubmit = (form) => {
			const model = {
				...form,
				position: curMaxPosition + 1
			};

			const payload = {
				model: model,
				namespace: getNamespace()
			};

			newPlaygroundGroup(payload);
		};

		return (
			<>
				<Modal
					title="Create Group"
					closeModal={closeModal}
					icon="fad fa-folders"
					modalState={open}
					headBgColor={colourCfg.blue}
					fontColor={colourCfg.white}
				>
					<PlaygroundGroupSettingsForm
						model={model}
						onSubmit={onSubmit}
						buttonBgColor={colourCfg.blue}
						buttonFontColor={colourCfg.white}
						buttonLabel="Create"
					/>
				</Modal>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(CloseNewPlaygroundGroupModalAction()),
		newPlaygroundGroup: (payload) => dispatch(PlaygroundGroupNewAction(payload))
	};
};

const mapStateToProps = (state, props) => {
	const mapped = {
		core: state.get("core")
	};

	const canvas = state.get("canvas");
	if (!canvas) {
		return mapped;
	}
	const playground = canvas.get("playground");
	if (!playground) {
		return mapped;
	}
	const playgroundGroup = playground.get("group");
	if (!playgroundGroup) {
		return mapped;
	}

	return {
		...mapped,
		playgroundGroup: playgroundGroup.get("new"),
		playgroundList: playground.get("list")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaygroundGroupNewModal);
