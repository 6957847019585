import React, { Component } from "react";
import { connect } from "react-redux";

import config from "../../../../../../config";
import { CfgColors } from "core/config";
import Modal from "core/components/common/modal/Modal";
import { getNestedState } from "core/lib/state";
import { CloseEditPhaseModalAction } from "../../../../../../actions/playground/inlet/filter/canvas/phase/edit";
import { CanvasNewAction } from "../../../../../../actions/playground/inlet/filter/canvas/new";
import PhaseEditForm from "../../../../../../components/playground/inlet/filter/canvas/phase/PhaseEditForm";
import { CanvasUpdateAction } from "../../../../../../actions/playground/inlet/filter/canvas/update";
import { updateCanvasPhase } from "../../../../../../lib/playground/canvas/phase/update";

class PhaseEditModal extends Component {
	render () {
		let { closeModal, phaseEdit, dashboard, updateCanvas } = this.props;
		if (!phaseEdit) {
			phaseEdit = {};
		}
		if (!dashboard) {
			dashboard = {};
		}

		let { phase } = phaseEdit;
		if (!phase) {
			phase = {};
		}

		const open = !!(phaseEdit && phaseEdit.open);
		if (!open) {
			return null;
		}

		if (phaseEdit.loading) {
			closeModal = () => {
				// Disabled.
			};
		}

		const colourCfg = config.get(CfgColors);

		const editState = {
			...phaseEdit,
			model: phaseEdit.phase
		};

		const onSubmit = (form) => {
			updateCanvas({
				namespace: phaseEdit.canvas.namespace,
				playgroundId: phaseEdit.canvas.playground_id,
				canvasId: phaseEdit.canvas.canvas_id,
				model: updateCanvasPhase(dashboard, phaseEdit.canvas.canvas_id, phaseEdit.phase.id, form)
			});

			closeModal();
		};

		return (
			<>
				<Modal
					title="Edit Phase"
					closeModal={closeModal}
					icon="fas fa-code-branch"
					modalState={open}
					headBgColor={colourCfg.purple}
					fontColor={colourCfg.white}
				>
					<PhaseEditForm
						editState={editState}
						onSubmit={onSubmit}
						buttonBgColor={colourCfg.purple}
						buttonFontColor={colourCfg.white}
						buttonLabel="Save"
					/>
				</Modal>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(CloseEditPhaseModalAction()),
		doCreateCanvas: (payload) => dispatch(CanvasNewAction(payload)),
		updateCanvas: (payload) => dispatch(CanvasUpdateAction(payload))
	};
};

const mapStateToProps = (state, props) => {
	const mapped = {
		core: state.get("core")
	};

	if (mapped.core) {
		mapped.profile = mapped.core.get("profile");
	}

	const playground = getNestedState(state, ["canvas", "playground"]);
	if (!playground) {
		return mapped;
	}

	const dashboard = getNestedState(playground, ["dashboard"]);
	if (!dashboard) {
		return mapped;
	}

	const phase = getNestedState(playground, ["inlet", "filter", "canvas", "phase"]);
	if (!phase) {
		return mapped;
	}

	return {
		...mapped,
		dashboard: dashboard,
		phaseEdit: phase.get("edit")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PhaseEditModal);
