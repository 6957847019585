import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./FilterNew.scss";

export class FilterNew extends Component {
	render () {
		const { inlet, playgroundProps, isFirst } = this.props;

		const { openNewFilter } = playgroundProps;

		return (
			<div
				className={classNames(
					"siphon-new",
					{ "is-first": isFirst }
				)}
			>
				<div
					className="new-actions"
					onClick={() => openNewFilter({ inlet: inlet })}
				>
					<div className="action-icon">
            <span className="icon">
              <i className="fa fa-plus"/>
            </span>
					</div>
					<div className="action-label">
						<span>Add Filter</span>
					</div>
				</div>
			</div>
		);
	}
}

FilterNew.propTypes = {
	playgroundProps: PropTypes.object.isRequired,
	inlet: PropTypes.object.isRequired,
	isFirst: PropTypes.bool
};

export default FilterNew;
