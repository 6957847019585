import React, { Component } from "react";
import { connect } from "react-redux";

import config from "../../config";
import { CfgColors } from "core/config";
import Modal from "core/components/common/modal/Modal";
import PlaygroundGroupSettingsForm from "../../components/playground/group/PlaygroundGroupSettingsForm";
import {
	CloseEditPlaygroundGroupModalAction,
	PlaygroundGroupUpdateAction
} from "../../actions/playground/group/update";
import { getNamespace } from "core/lib/auth";

class PlaygroundGroupEditModal extends Component {
	render () {
		let { closeModal, playgroundGroup, editPlaygroundGroup } = this.props;
		if (!playgroundGroup) {
			playgroundGroup = {};
		}
		let model = playgroundGroup.model;
		if (!model) {
			model = {};
		}
		const open = !!(playgroundGroup && playgroundGroup.open);
		if (!open) {
			return null;
		}

		if (playgroundGroup.loading) {
			closeModal = () => {
				// Disabled.
			};
		}

		const colourCfg = config.get(CfgColors);

		const onSubmit = (form) => {
			const payload = {
				model: form,
				groupId: form.id,
				namespace: getNamespace()
			};

			editPlaygroundGroup(payload);
		};

		return (
			<>
				<Modal
					title="Edit Group"
					closeModal={closeModal}
					icon="fad fa-folders"
					modalState={open}
					headBgColor={colourCfg.blue}
					fontColor={colourCfg.white}
				>
					<PlaygroundGroupSettingsForm
						model={model}
						onSubmit={onSubmit}
						buttonBgColor={colourCfg.blue}
						buttonFontColor={colourCfg.white}
						buttonLabel="Save"
					/>
				</Modal>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(CloseEditPlaygroundGroupModalAction()),
		editPlaygroundGroup: (payload) => dispatch(PlaygroundGroupUpdateAction(payload))
	};
};

const mapStateToProps = (state, props) => {
	const mapped = {
		core: state.get("core")
	};

	const canvas = state.get("canvas");
	if (!canvas) {
		return mapped;
	}
	const playground = canvas.get("playground");
	if (!playground) {
		return mapped;
	}

	return {
		...mapped,
		playgroundGroup: playground.get("group").get("edit")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaygroundGroupEditModal);
