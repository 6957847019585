import { getSpriteKeyMap } from "./util";
import Color from "color";

export * from "./util";

const { Map } = require("immutable");

// Constants

export const NamespaceUpwire = "upwire";

// Base

export const CfgColors = "colors";
export const CfgStyles = "styles";
export const CfgIconStyles = "iconStyles";
export const CfgTitleBgColors = "titleBgColors";
export const CfgPickerTitleBgColors = "pickerTitleBgColors";

export const CfgBlankSplashUrl = "blankSplashUrl";

export const OptFill = "fill";
export const OptFillHover = "fillHover";
export const OptIconFill = "iconFill";
export const OptIconFillHover = "iconFillHover";
export const OptIconSvgSprite = "iconSvgSprite";
export const OptIconSvgSpriteHover = "iconSvgSpriteHover";
export const OptIcon = "icon"; // alias for iconSvgSprite
export const OptIconHover = "iconHover"; // alias for iconSvgSpriteHover
export const OptIconSvg = "iconSvg";
export const OptIconUrl = "iconUrl";
export const OptIconUrlHover = "iconUrlHover";
export const OptIconPad = "iconPad";
export const OptIconPadPercent = "iconPadPercent";
export const OptIconHoverPad = "iconHoverPad";
export const OptIconHoverPadPercent = "iconHoverPadPercent";

export const SvgSpriteIconSetCommunication = "ICON_SET_COMMUNICATION";
export const SvgSpriteFontAwesomeRegular = "FA_REGULAR";
export const SvgSpriteFontAwesomeBrands = "FA_BRANDS";

// Variables

export let sprites = {};
export let spriteMap = {};

export const setSprites = (val) => {
	sprites = val;
	spriteMap = getSpriteKeyMap();
};

// Defaults

const defaultConfig = {};

defaultConfig[CfgColors] = {
	white: "#FFFFFF",
	black: "#000000",
	grey: "#EFEFEF",
	grey2: "#DDD",
	greyDark: "#999",
	greyDark2: "#777",
	greyDarkLight: "#F5F5F5",
	blue: "#2b98f0",
	darkBlue: "#4054b2",
	altBlue: "#536cff",
	red: "#f23611",
	green: "#46b748",
	greenPrimary: "#03d5c4",
	purple: "#663fb4",
	pink: "#ff4181",
	yellow: "#fec900",
	yellowLight: "#fedf66",
	upwireGreen1: "#219653",
	upwireGreen2: "#27AE60",
	upwireGreen2Light: "#E9F7EF",
	upwireGreen3: "#6FCF97",
	upwireBlue1: "#3A5CB6",
	upwireBlue2: "#2D9CDB",
	upwireBlue3: "#56CCF2",
	upwireBlueAlt1: "#4747B5",
	upwireBlueAlt2: "#536DFF",
	upwireBlueAlt3: "#3A5CB6",
	upwireBlueAlt4: "#00CEC7",
	upwirePurple1: "#9B51E0",
	upwirePurple2: "#BB6BD9",
	upwirePurple2Light: "#F8F0FB",
	upwireYellow1: "#F2C94C",
	upwireYellow2: "#FEC009",
	upwireYellow2Light: "#FFF9E6",
	upwireOrange1: "#FF9700",
	upwirePink1: "#FC5768",
	upwireRed1: "#EB5757",
	upwireRed2: "#D32F2F"
};

defaultConfig[CfgTitleBgColors] = [
	defaultConfig[CfgColors].upwireBlue2,
	defaultConfig[CfgColors].upwirePurple1,
	defaultConfig[CfgColors].upwireGreen1,
	defaultConfig[CfgColors].upwireYellow2,
	defaultConfig[CfgColors].upwireOrange1,
	defaultConfig[CfgColors].upwireBlue1,
	defaultConfig[CfgColors].upwirePurple2,
	defaultConfig[CfgColors].upwireGreen2,
	defaultConfig[CfgColors].upwirePink1,
	defaultConfig[CfgColors].blue,
	defaultConfig[CfgColors].purple,
	defaultConfig[CfgColors].green,
	defaultConfig[CfgColors].yellow,
	defaultConfig[CfgColors].pink
];

defaultConfig[CfgPickerTitleBgColors] = [
	defaultConfig[CfgColors].upwireBlue1,
	defaultConfig[CfgColors].upwireBlue2,
	defaultConfig[CfgColors].upwireBlue3,
	defaultConfig[CfgColors].upwirePurple1,
	defaultConfig[CfgColors].upwirePurple2,
	defaultConfig[CfgColors].upwireGreen1,
	defaultConfig[CfgColors].upwireYellow2,
	defaultConfig[CfgColors].upwireOrange1
];

// Sometimes the icons need to be adjusted
// (either for optical illusion or different padding size).
defaultConfig[CfgIconStyles] = {
	set: [{
		sets: [SvgSpriteIconSetCommunication],
		styles: {
			padPercent: 7
		}
	}],
	sprite: [{
		sprites: ["far-construction", "fas-construction", "fad-construction"],
		styles: {
			// Optical illusion of triangle edges.
			offsetTopPercent: -5
		}
	}, {
		sprites: ["fas-play"],
		styles: {
			// Optical illusion of triangle edges.
			offsetLeftPercent: 10
		}
	}, {
		sprites: ["pe-is-c-outgoing-call-2-f"],
		styles: {
			// Optical illusion due to unbalanced weighting.
			offsetLeftPercent: 5
		}
	}, {
		sprites: ["pe-is-c-outgoing-call-1"],
		styles: {
			// Optical illusion due to unbalanced weighting.
			offsetTopPercent: 1
		}
	}, {
		sprites: ["pe-is-c-email-out"],
		styles: {
			// Optical illusion due to unbalanced weighting.
			offsetTopPercent: -4
		}
	}]
};

defaultConfig[CfgStyles] = {
	contextMenuBackgroundColor: Color("#333").alpha("0.95").string(),
	contextMenuFontColor: Color("#FFF").darken("0.05").string()
};

export const ConfigMap = { ...defaultConfig };

export const config = Map(ConfigMap);

export default config;
