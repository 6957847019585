import {ComponentProps, FC} from "react";
import {ScreenContextProvider} from "./ScreenContextProvider";
import {UserContextProvider} from "./UserContextProvider";

const combineComponents = (...components: FC[]): FC => {
    return components.reduce((AccumulatedComponents, CurrentComponent) => {
            return ({children}: ComponentProps<FC>): JSX.Element => {
                return (
                    <AccumulatedComponents>
                        <CurrentComponent>{children}</CurrentComponent>
                    </AccumulatedComponents>
                );
            };
        },
        ({children}) => <>{children}</>,
    );
};

const providers = [
    ScreenContextProvider,
    UserContextProvider
];

export const AppContextProvider: FC = combineComponents(...providers);

