import { apiFetch } from "../../../base";
import { getNamespace } from "../../../../lib/auth";
import { EnvironmentScopeNamespace } from "../../../../lib/constants";

export function * putEnvironmentVariable (payload) {
	const { model } = payload;
	const { scope, environmentName, path, name, newName, ...rest } = model;

	const namespace = getNamespace();

	if (newName) {
		rest.name = newName;
	}

	return yield apiFetch({
		endpoint: `/data/v1/environment/${namespace}/${environmentName}/variable/${path}:/${name}`,
		method: "PUT",
		authenticated: true,
		withoutAccount: (scope === EnvironmentScopeNamespace),
		body: JSON.stringify(rest)
	});
}
