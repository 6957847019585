import React from "react";
import * as Yup from "yup";

import InputField from "core/components/common/form/InputField";

export const getBasicInitialValues = (model) => {
	return {
		name: model.name || "",
		enabled: model.enabled || false
	};
};

export const getBasicYupSchema = () => {
	return {
		name: Yup.string()
			.required("Name is required")
	};
};

const BasicFields = ({ values, ...props }) => {
	return (
		<>
			<InputField
				key="name" name="name" label="Name" values={values}
				type="text" placeholder="Phase name"
				{...props}
			/>
		</>
	);
};

export default BasicFields;
