import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import config from "../../../../../../../config";
import { CfgColors } from "core/config";

import "./SetTargetContent.scss";

const colourCfg = config.get(CfgColors);

const SetTargetAction = ({ title, icon, color, onClick, disabled }) => {
	if (disabled) {
		color = colourCfg.grey;
		onClick = () => {};
	}

	return (
		<div
			className={classNames(
				"set-target-action",
				{ "is-disabled": disabled }
			)} onClick={onClick}
		>
			<div className="target-icon" style={{ backgroundColor: color }}>
        <span className="icon">
          <i className={icon}/>
        </span>
			</div>
			<div className="target-label">
				{title}
			</div>
		</div>
	);
};

SetTargetAction.propTypes = {
	title: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool
};

class SetTargetContent extends Component {
	render () {
		const {
			newEnabled, existingEnabled, terminateEnabled,
			openNewNode, openExisting, openTerminate, closeModal
		} = this.props;

		return (
			<>
				<section className="modal-card-body">
					<div className="set-target-actions">
						<SetTargetAction
							title="Create Node"
							icon="fas fa-chart-network"
							color={colourCfg.green}
							onClick={openNewNode}
							disabled={!newEnabled}
						/>
						<SetTargetAction
							title="Select Existing"
							icon="fas fa-list"
							color={colourCfg.yellow}
							onClick={openExisting}
							disabled={!existingEnabled}
						/>
						<SetTargetAction
							title="Terminate"
							icon="fas fa-traffic-light-stop"
							color={colourCfg.red}
							onClick={openTerminate}
							disabled={!terminateEnabled}
						/>
					</div>
				</section>
				<footer className="modal-card-foot">
					<button type="button" className="button" onClick={closeModal}>Cancel</button>
				</footer>
			</>
		);
	}
}

SetTargetContent.propTypes = {
	newEnabled: PropTypes.bool,
	existingEnabled: PropTypes.bool,
	terminateEnabled: PropTypes.bool,
	openNewNode: PropTypes.func.isRequired,
	openExisting: PropTypes.func.isRequired,
	openTerminate: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired
};

export default SetTargetContent;
