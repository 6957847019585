import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./LogicConditionSummary.scss";

export class LogicConditionSummary extends Component {
	static propTypes = {
		condition: PropTypes.object,
		subText: PropTypes.string,
		onEdit: PropTypes.func.isRequired
	};

	countConditions = (condition) => {
		let total = 0;

		if (condition) {
			if (condition.rules) {
				total += condition.rules.length;
			}
			if (condition.groups && condition.groups.length > 0) {
				condition.groups.forEach(g => {
					total += this.countConditions(g);
				});
			}
		}

		return total;
	};

	render () {
		const { condition, subText, onEdit } = this.props;

		const totalConditions = this.countConditions(condition);

		return (
			<div
				className={classNames(
					"logic-condition-summary",
					"text-selection-none",
					{ "with-conditions": (totalConditions > 0) })}
				onClick={() => {
					onEdit(condition);
				}}
			>
				<div className={classNames("summary", { "with-subtext": subText })}>
					<div className="summary-label">{totalConditions} Conditions</div>
					{subText
						? <div className="summary-sub-text">{subText}</div>
						: ""}
				</div>
				<div className="action">
          <span className="icon">
            <i className="fad fa-edit"/>
          </span>
				</div>
			</div>
		);
	}
}

export default LogicConditionSummary;
