import React, { Component } from "react";
import { connect } from "react-redux";

import config from "../../../../config";
import { CfgColors } from "core/config";
import Modal from "core/components/common/modal/Modal";
import { getNestedState } from "core/lib/state";
import { CloseNewFilterModalAction } from "../../../../actions/playground/inlet/filter/new";
import { CanvasNewAction } from "../../../../actions/playground/inlet/filter/canvas/new";
import FilterEditForm from "../../../../components/playground/inlet/filter/edit/FilterEditForm";
import { addNewFilter, extractUpdatablePlayground } from "../../../../lib/playground/update";
import { getNamespace } from "core/lib/auth";

class FilterNewModal extends Component {
	render () {
		let { closeModal, filterNew, doCreateCanvas, dashboard } = this.props;
		if (!filterNew) {
			filterNew = {};
		}
		if (!dashboard) {
			dashboard = {};
		}

		const open = !!(filterNew && filterNew.open);
		if (!open) {
			return null;
		}

		if (filterNew.loading) {
			closeModal = () => {
				// Disabled.
			};
		}

		const colourCfg = config.get(CfgColors);

		const onSubmit = (form) => {
			const { name, ...filterOpts } = form;
			const [playground, filterId] = addNewFilter(
				extractUpdatablePlayground(dashboard), filterNew.inlet.id, filterOpts);

			const model = {
				playground: playground,
				canvas: {
					name: name
				},
				// Filter id to add canvas to.
				filter_id: filterId
			};

			doCreateCanvas({
				namespace: getNamespace(),
				playgroundId: playground.playground_id,
				model: model
			});
		};

		return (
			<>
				<Modal
					title="Create Filter"
					closeModal={closeModal}
					icon="fas fa-code-branch"
					modalState={open}
					headBgColor={colourCfg.yellow}
					fontColor={colourCfg.white}
				>
					<FilterEditForm
						editState={filterNew}
						onSubmit={onSubmit}
						buttonBgColor={colourCfg.yellow}
						buttonFontColor={colourCfg.white}
						buttonLabel="Create"
					/>
				</Modal>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(CloseNewFilterModalAction()),
		doCreateCanvas: (payload) => dispatch(CanvasNewAction(payload))
	};
};

const mapStateToProps = (state, props) => {
	const mapped = {
		core: state.get("core")
	};

	const playground = getNestedState(state, ["canvas", "playground"]);
	if (!playground) {
		return mapped;
	}

	const dashboard = getNestedState(playground, ["dashboard"]);
	if (!dashboard) {
		return mapped;
	}

	const filter = getNestedState(playground, ["inlet", "filter"]);
	if (!filter) {
		return mapped;
	}

	return {
		...mapped,
		dashboard: dashboard,
		filterNew: filter.get("new")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterNewModal);
