import React from "react";
import classNames from "classnames";

import config, { CfgColors, CfgStyles } from "../../../config";

import "./ContextMenuContent.scss";

const cfgColors = config.get(CfgColors);
const cfgStyles = config.get(CfgStyles);

export const ContextMenuPositionVerticalDefault = "default";
export const ContextMenuPositionVerticalCenter = "center";

export const ContextMenuPositionHorizontalRight = "right";

export const ContextMenuItem = ({ label, icon, doClose, onClick }) => {
	const handleClick = () => {
		doClose();
		onClick();
	};

	return (
		<div className="core-context-menu-item" onClick={handleClick}>
      <span className="icon-text">
        <span className="icon">
          <i className={icon} aria-hidden="true"/>
        </span>
        <span className="menu-item-label">{label}</span>
      </span>
		</div>
	);
};

export class ContextMenuContent extends React.PureComponent {
	componentDidMount () {
		document.addEventListener("mousedown", this.handleClick, false);
		document.addEventListener("scroll", this.handleScroll, false);

		this.adjustPositionOnMount();
	}

	componentWillUnmount () {
		document.removeEventListener("mousedown", this.handleClick, false);
		document.removeEventListener("scroll", this.handleScroll, false);
	}

	handleClick = (e) => {
		const { doClose } = this.props;
		if (!doClose) {
			return;
		}

		if (!this.node) {
			return;
		}

		if (this.node.contains(e.target)) {
			return;
		}

		doClose();
	};

	handleScroll = (e) => {
		const { doClose } = this.props;

		// TODO: Maybe move it instead? Dis good for now.

		doClose();
	};

	adjustPositionOnMount = () => {
		const { eventX, eventY, positionHorizontal } = this.props;

		if (!this.node) {
			return;
		}

		if (positionHorizontal !== ContextMenuPositionHorizontalRight) {
			const maxX = document.body.clientWidth - this.node.offsetWidth;

			if (eventX > maxX) {
				this.node.style.left = maxX + "px";
			}
		} else {
			if (eventX <= this.node.clientWidth) {
				this.node.style.left = 0 + "px";
			}
		}

		const maxY = document.body.clientHeight - this.node.offsetHeight;

		if (eventY > maxY) {
			this.node.style.top = maxY + "px";
		}
	};

	render () {
		let {
			doClose, actions, positionVertical, positionHorizontal, eventX, eventY,
			backgroundColor, fontColor, itemActiveColor, itemActiveFontColor
		} = this.props;

		if (!backgroundColor) {
			backgroundColor = cfgStyles.contextMenuBackgroundColor;
		}
		if (!fontColor) {
			fontColor = cfgStyles.contextMenuFontColor;
		}
		if (!itemActiveColor) {
			itemActiveColor = cfgColors.blue;
		}
		if (!itemActiveFontColor) {
			itemActiveFontColor = cfgColors.white;
		}

		return (
			<div
				ref={node => {
					this.node = node;
				}}
				className={classNames(
					"core-context-menu",
					"vert-" + positionVertical,
					"horiz-" + positionHorizontal
				)}
				style={{
					top: eventY + "px",
					left: eventX + "px",
					"--background-color": backgroundColor,
					"--font-color": fontColor,
					"--item-active-color": itemActiveColor,
					"--item-active-font-color": itemActiveFontColor
				}}
			>
				{actions.map(a => {
					return (
						<ContextMenuItem
							key={a.label}
							doClose={doClose}
							{...a}
						/>
					);
				})}
			</div>
		);
	}
}
