import React, { Component } from "react";

import PropTypes from "prop-types";

import "./WindowTaskBar.scss";

export const TaskBarAction = ({ icon, onClick }) => {
	return (
		<div className="action" onClick={onClick}>
      <span className="icon">
        <i className={icon}/>
      </span>
		</div>
	);
};

export class WindowTaskBar extends Component {
	render () {
		const { start, title, actions } = this.props;

		return (
			<div className="task-bar">

				<div className="start">
					{start || ""}
				</div>

				<div className="middle text-selection-none">
					<span>{title}</span>
				</div>

				<div className="end">
					{actions ? (<div className="task-actions">{actions}</div>) : ""}
				</div>

			</div>
		);
	}
}

WindowTaskBar.propTypes = {
	title: PropTypes.string,
	start: PropTypes.array,
	actions: PropTypes.array
};

export default WindowTaskBar;
