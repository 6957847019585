import React from "react";
import {makeAutoReducer} from "../util/reducers";

export interface IUserContext {
    token: string;
}

const initialScreenState: IUserContext = {
    token: ""
};

export const UserContext = React.createContext({
    state: initialScreenState,
    dispatch: (update: Partial<IUserContext>) => {
    }
});

export const UserContextProvider = ({children}: { children?: React.ReactNode }) => {
    const [state, dispatch] = React.useReducer(makeAutoReducer<IUserContext>(), initialScreenState);
    return (
        <UserContext.Provider value={{state: state, dispatch: dispatch}}>
            {children}
        </UserContext.Provider>
    );
};

