import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./InletNewAction.scss";

export class InletNewAction extends Component {
	state = {
		isHover: false
	};

	setHover = (isHover) => {
		this.setState(() => ({
			isHover: isHover
		}));

		return false;
	};

	render () {
		const { isHover } = this.state;
		const { inletCount } = this.props;

		return (
			<div
				className={classNames(
					"pg-inlet-new-action",
					{ "have-inlets": (inletCount > 0) }
				)}
				onMouseOver={() => this.setHover(true)}
				onMouseLeave={() => this.setHover(false)}
			>
				<div
					className={classNames(
						"start",
						{ hover: (isHover > 0) }
					)}
				>
					<div className="pg-inlet-new-icon">
            <span className="icon">
              <i className="fa fa-plus"/>
            </span>
					</div>
					<div className="pg-inlet-new-label">
						<span>Add Inlet</span>
					</div>
				</div>
			</div>
		);
	}
}

InletNewAction.propTypes = {
	playgroundProps: PropTypes.object.isRequired,
	inletCount: PropTypes.number.isRequired
};

export default InletNewAction;
