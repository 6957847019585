import { DefaultLinkFactory } from "@projectstorm/react-diagrams";

export class CanvasInitFactory extends DefaultLinkFactory {
	constructor () {
		super("canvas-init");
	}

	generateReactWidget (event) {
		/*
		const colors = config.get(CfgColors);

		return (
		  <>
			<defs>
			  <ArrowHeadSvgMarker id="yellow-arrow" fill={colors.yellow}/>
			</defs>

			<ArrowSvgElem x={25} y={0} length={50} markerEnd="url(#yellow-arrow)" stroke={colors.yellow}
						  direction={'down'}/>
		  </>
		);
		*/
	}
}
