import React, { Component } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import InlineError from "core/components/common/message/InlineError";
import Tabs, { ErrorSection, Tab } from "core/components/common/tabs/Tabs";
import VariableFields, { getDataVariableInitialValues, getVariablesYupSchema } from "./variables/VariableFields";
// import NumberFields, { getNumbersInitialValues, getNumbersYupSchema } from './numbers/NumberFields'
// import TemplateFields, { getTemplatesInitialValues, getTemplatesYupSchema } from './template/TemplateFields'
// import FromAddressFields, { getEmailFromAddressesInitialValues, getEmailFromAddressesYupSchema } from './email/FromAddressFields'
// import FileFields, { getFilesInitialValues, getFilesYupSchema } from './file/FileFields'
// import SendWindowFields, { getSendWindowsInitialValues, getSendWindowsYupSchema } from './advanced/SendWindowFields'
import Loading from "components/common/loading/Loading";
import BasicFields, { getBasicInitialValues, getBasicYupSchema } from "./basic/BasicFields";

const topLevelVariables = [
	"name",
	"group_id"
];

const PlaygroundSettingsFormMessages = ({ error }) => {
	if (error) {
		return (
			<InlineError>
				<p>There was an error processing your request, please try again.</p>
			</InlineError>
		);
	}

	return "";
};

const PlaygroundSettingsFormSchema = Yup.object().shape({
	...getBasicYupSchema(),
	variables: getVariablesYupSchema()
	// sms_templates: getTemplatesYupSchema(),
	// sms_raw_content_templates: getTemplatesYupSchema(),
	// voice_templates: getTemplatesYupSchema(),
	// email_templates: getTemplatesYupSchema(),
	// email_html_content_templates: getTemplatesYupSchema(),
	// email_text_content_templates: getTemplatesYupSchema(),
	// email_from_addresses: getEmailFromAddressesYupSchema(),
	// email_attachments: getFilesYupSchema(),
	// send_windows: getSendWindowsYupSchema()
});

const applyModelToForm = (model) => {

	const form = {};
	const cloneModel = cloneDeep(model);

	// Copy top level
	topLevelVariables.forEach(v => {
		const val = cloneModel[v];
		if (val !== undefined && val !== null) {
			form[v] = val;
		}
	});

	// Copy settings
	if (cloneModel.settings) {
		Object.keys(cloneModel.settings).forEach(fk => {
			form[fk] = cloneModel.settings[fk];
		});
	}

	return form;
};

const applyFormToModel = (form, model) => {
	const cloneForm = cloneDeep(form);
	const newModel = cloneDeep(model);

	// Copy top level
	topLevelVariables.forEach(v => {
		if (cloneForm[v] !== undefined && cloneForm[v] !== null) {
			newModel[v] = cloneForm[v];
		}

		delete (cloneForm[v]);
	});

	// Copy settings
	if (!newModel.settings) {
		newModel.settings = {};
	}

	Object.keys(cloneForm).forEach(fk => {
		newModel.settings[fk] = cloneForm[fk];
	});

	// Nullify group_id
	if (!newModel.group_id) {
		newModel.group_id = undefined;
	}

	return newModel;
};

export class PlaygroundSettingsForm extends Component {
	render () {

		let { model, groups, onSubmit, buttonBgColor, buttonFontColor, buttonLabel } = this.props;

		if (!model) {
			model = {};
		}

		if (!buttonLabel) {
			buttonLabel = "Save";
		}

		const loading = model.loading;
		const error = model.error;

		const formData = applyModelToForm(model);

		const onSubmitAdaptor = (form) => {
			return onSubmit(applyFormToModel(form, model));
		};

		return (
			<Formik
				onSubmit={onSubmitAdaptor}
				initialValues={{
					...getBasicInitialValues(formData),
					variables: getDataVariableInitialValues(formData)
				}}
				validationSchema={PlaygroundSettingsFormSchema}
			>
				{({ errors, touched, ...props }) => (
					<Form className="up-form with-tabs">
						<section className="modal-card-body scroll-overflow">
							{loading
								? <Loading/>
								: (
									<>
										<Tabs errors={errors} touched={touched} borderColor={buttonBgColor} {...props}>
											<ErrorSection>
												<PlaygroundSettingsFormMessages error={error}/>
											</ErrorSection>

											<Tab id="basic" title="Basic" fields={["name", "group_id"]}>
												<BasicFields groups={groups} errors={errors}
															 touched={touched} {...props} />
											</Tab>

											<Tab id="variables" title="Variables" fields={["variables"]}>
												<VariableFields errors={errors} touched={touched} {...props} />
											</Tab>

										</Tabs>
									</>
								)}
						</section>
						<footer className="modal-card-foot">
							<button
								type="submit" className="button is-primary" disabled={loading} style={{
								backgroundColor: buttonBgColor,
								color: buttonFontColor
							}}
							>{buttonLabel}
							</button>
						</footer>
					</Form>
				)}
			</Formik>
		);
	}
}

PlaygroundSettingsForm.propTypes = {
	model: PropTypes.object.isRequired,
	groups: PropTypes.array.isRequired,
	onSubmit: PropTypes.func.isRequired,
	buttonBgColor: PropTypes.string.isRequired,
	buttonFontColor: PropTypes.string.isRequired
};

export default PlaygroundSettingsForm;
