import { call, put, takeLatest } from "redux-saga/effects";
import { getTokenRefresh } from "../../api/auth/token";
import {
	LogoutAction,
	TokenExpiredAction,
	TokenRefreshAction,
	TokenRefreshFailedAction,
	TokenRefreshRequestAction,
	TokenRefreshSuccessAction
} from "../../actions/auth";

/// / Token Refresh
// Worker
function * getTokenRefreshWorker (action) {
	try {
		yield put(TokenRefreshRequestAction());
		const r = yield call(getTokenRefresh);
		if (r.json) {
			yield put(TokenRefreshSuccessAction(r.json));
		} else {
			yield put(TokenRefreshFailedAction("Failed to login user"));
		}
	} catch (e) {
		yield put(TokenRefreshFailedAction(e));
	}
}

// Saga
export function * getTokenRefreshSaga () {
	yield takeLatest(TokenRefreshAction.getType(), getTokenRefreshWorker);
}

/// / Token Expired
// Worker
function * getTokenExpired (action) {
	yield put(LogoutAction());
}

// Saga
export function * getTokenExpiredSaga () {
	yield takeLatest(TokenExpiredAction.getType(), getTokenExpired);
}

// Export

export const tokenSagas = [
	getTokenRefreshSaga(),
	getTokenExpiredSaga()
];

export default tokenSagas;
