import { DefaultLinkModel } from "@projectstorm/react-diagrams";
import { BezierCurve } from "@projectstorm/geometry";

export class ArrowLinkModel extends DefaultLinkModel {
	constructor (type, color, selectedColor) {
		super({
			type: type,
			width: 3,
			color: color,
			selectedColor: selectedColor,
			curvyness: 30
		});
	}

	getSVGPath () {
		if (this.points.length === 4) {
			const curve = new BezierCurve();
			const firstPoint = this.points[1];
			const lastPoint = this.points[this.points.length - 2];
			curve.setSource(firstPoint.getPosition());
			curve.setTarget(lastPoint.getPosition());
			curve.setSourceControl(
				firstPoint
					.getPosition()
					.clone()
			);
			curve.setTargetControl(
				lastPoint
					.getPosition()
					.clone()
			);

			if (this.sourcePort) {
				curve.getSourceControl().translate(...this.calculateControlOffset(this.getSourcePort()));
			}

			if (this.targetPort) {
				curve.getTargetControl().translate(...this.calculateControlOffset(this.getTargetPort()));
			}
			return curve.getSVGCurve();
		}
	}
}
