import {Alert} from "@mui/material";
import React, {FC} from "react";
import {connect} from "react-redux";
import {authTokenPropProvider} from "./tokenProvider";


export function authenticatedComponent<TProps extends {}>(
    Component: FC<TProps>,
    creator: (token: any) => TProps
) {

    function AuthLoader(props: any) {

        const {token} = props;
        if (!token)
            return <Alert severity="warning">Authenticating...</Alert>;

        const componentProps = creator(token);
        return <Component {...componentProps}/>;
    }

    return connect(authTokenPropProvider)(AuthLoader) as any as FC;
}
