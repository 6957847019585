import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import config from "../../../../../../config";
import { CfgColors } from "core/config";
import { clickCanvasPhaseContextMenuAction } from "../../../../../../lib/playground/canvas/phase/actions";

import PhaseTabNew from "./PhaseTabNew";

import "./PhaseTabs.scss";

const colourCfg = config.get(CfgColors);

const PhaseTabAction = ({ color, icon, onClick }) => {
	return (
		<div
			className="action"
			style={{
				"--hover-color": color
			}}
			onClick={onClick}
		>
			<span className="icon"><i className={icon}/></span>
		</div>
	);
};

export const PhaseTab = ({ playgroundProps, phase, canvas, id, title, activeTab, setActiveTab }) => {
	const isActive = (id === activeTab);

	const handleContextMenu = clickCanvasPhaseContextMenuAction(playgroundProps, canvas, phase);

	return (
		<li
			id={"tab-" + id}
			className={classNames({ "is-active": isActive })}
		>
			<div
				className="tab-label text-selection-none"
				onClick={() => setActiveTab(id)}
				onContextMenu={handleContextMenu}
			>
				<div>{title}</div>
			</div>
			<div className="tab-actions">
				<PhaseTabAction
					icon="fas fa-ellipsis-h"
					color={colourCfg.purple}
					onClick={handleContextMenu}
				/>
			</div>
		</li>
	);
};

export const PhaseTabs = ({ playgroundProps, canvas, phase, setPhaseId }) => {
	let activeTab;
	if (phase) {
		activeTab = phase.id;
	}

	const phases = canvas.schema.phases;

	const setActiveTab = (id) => {
		setPhaseId(id);
	};

	const tabs = phases.map((p) =>
		<PhaseTab
			key={"pg-phase-tab-" + p.id}
			phase={p}
			id={p.id}
			title={p.name}
			canvas={canvas}
			activeTab={activeTab}
			playgroundProps={playgroundProps}
			setActiveTab={setActiveTab}
		/>
	);

	return (
		<>
			<div className="pg-phase-tabs">
				<ul>
					{tabs}
					<li className="pg-phase-new-tab">
						<PhaseTabNew playgroundProps={playgroundProps} setPhaseId={setPhaseId} canvas={canvas}/>
					</li>
				</ul>
			</div>
		</>
	);
};

PhaseTabs.propTypes = {
	playgroundProps: PropTypes.object.isRequired,
	canvas: PropTypes.object.isRequired,
	phase: PropTypes.object,
	setPhaseId: PropTypes.func.isRequired
};

export default PhaseTabs;
