import { applyMiddleware, compose, createStore } from "redux";
import { fromJS } from "immutable";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./reducers";
import rootSaga from "./sagas";
import tokenRefreshSaga from "core/sagas/token";
import { responsiveStoreEnhancer } from "redux-responsive";

export default function configureStore (initialState = fromJS({})) {
	const composeEx = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose);

	const sagaMiddleware = createSagaMiddleware();
	const tokenRefreshMiddleware = createSagaMiddleware();

	const store = createStore(
		rootReducer,
		initialState,
		composeEx(
			responsiveStoreEnhancer,
			applyMiddleware(sagaMiddleware, tokenRefreshMiddleware)
		)
	);

	sagaMiddleware.run(rootSaga);
	tokenRefreshMiddleware.run(tokenRefreshSaga);
	return store;
}
