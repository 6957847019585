import React, { Component } from "react";
import { connect } from "react-redux";

import config from "../../../../config";
import { CfgColors } from "core/config";

import Modal from "core/components/common/modal/Modal";
import { getNestedState } from "core/lib/state";
import FilterEditForm from "../../../../components/playground/inlet/filter/edit/FilterEditForm";
import { CloseEditFilterModalAction } from "../../../../actions/playground/inlet/filter/edit";
import {
	extractCanvasByFilter,
	extractFilterFromDashboard,
	extractUpdatableEditFilterModel,
	updateFilter
} from "../../../../lib/playground/update";
import { CanvasUpdateAction } from "../../../../actions/playground/inlet/filter/canvas/update";
import { extractUpdatableCanvas } from "../../../../lib/playground/canvas/update";

class FilterEditModal extends Component {
	render () {
		let { closeModal, filterEdit, dashboard, doUpdateCanvas } = this.props;
		if (!filterEdit) {
			filterEdit = {};
		}
		if (!dashboard) {
			dashboard = {};
		}

		let { filter } = filterEdit;
		if (!filter) {
			filter = {};
		}

		const open = !!(filterEdit && filterEdit.open);
		if (!open) {
			return null;
		}

		if (filterEdit.loading) {
			closeModal = () => {
				// Disabled.
			};
		}

		const colourCfg = config.get(CfgColors);

		const editState = {
			...filterEdit,
			model: extractUpdatableEditFilterModel(dashboard, filter.id)
		};

		const onSubmit = (form) => {
			const { name, ...filterOpts } = form;
			const { playground } = dashboard;

			// Get Filter
			const flt = extractFilterFromDashboard(dashboard, filter.id);

			// Get Canvas
			const canvas = extractCanvasByFilter(dashboard, flt);

			// Construct payload
			const payload = extractUpdatableCanvas(dashboard, canvas.canvas_id);

			// Update payload
			payload.canvas.name = name;
			payload.playground = updateFilter(payload.playground, filter.id, filterOpts);

			doUpdateCanvas({
				namespace: playground.namespace,
				playgroundId: playground.playground_id,
				canvasId: canvas.canvas_id,
				model: payload
			});

			closeModal();
		};

		return (
			<>
				<Modal
					title="Edit Filter"
					closeModal={closeModal}
					icon="fas fa-code-branch"
					modalState={open}
					headBgColor={colourCfg.yellow}
					fontColor={colourCfg.white}
				>
					<FilterEditForm
						editState={editState}
						onSubmit={onSubmit}
						buttonBgColor={colourCfg.yellow}
						buttonFontColor={colourCfg.white}
						buttonLabel="Save"
					/>
				</Modal>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(CloseEditFilterModalAction()),
		doUpdateCanvas: (payload) => dispatch(CanvasUpdateAction(payload))
	};
};

const mapStateToProps = (state, props) => {
	const mapped = {};

	const playground = getNestedState(state, ["canvas", "playground"]);
	if (!playground) {
		return mapped;
	}

	const dashboard = getNestedState(playground, ["dashboard"]);
	if (!dashboard) {
		return mapped;
	}

	const filter = getNestedState(playground, ["inlet", "filter"]);
	if (!filter) {
		return mapped;
	}

	return {
		...mapped,
		dashboard: dashboard,
		filterEdit: filter.get("edit")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterEditModal);
