import React, { Component } from "react";
import { connect } from "react-redux";

import config from "../../../../../../../config";
import { CfgColors } from "core/config";
import Modal from "core/components/common/modal/Modal";
import { getNestedState } from "core/lib/state";
import { CloseNodeNewModalAction } from "../../../../../../../actions/playground/inlet/filter/canvas/phase/node/new";
import NodeNewContent
	from "../../../../../../../components/playground/inlet/filter/canvas/phase/node/new/NodeNewContent";

class NodeNewModal extends Component {
	render () {
		let { closeModal, nodeNew, playgroundDashboard } = this.props;
		if (!nodeNew) {
			return null;
		}
		if (!playgroundDashboard) {
			playgroundDashboard = {};
		}

		const open = !!(nodeNew && nodeNew.open);
		if (!open) {
			return null;
		}

		if (nodeNew.loading) {
			closeModal = () => {
				// Disabled.
			};
		}

		const handleClose = () => {
			closeModal();

			if (nodeNew.onClose) {
				nodeNew.onClose();
			}
		};

		const colourCfg = config.get(CfgColors);

		return (
			<>
				<Modal
					title="Create Node"
					closeModal={handleClose}
					icon="fas fa-chart-network"
					modalState={open}
					headBgColor={colourCfg.green}
					fontColor={colourCfg.white}
				>
					<NodeNewContent
						model={nodeNew}
						playgroundDashboard={playgroundDashboard}
						onSelect={(payload) => nodeNew.onSelect(payload)}
						closeModal={handleClose}
						borderColor={colourCfg.green}
					/>
				</Modal>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(CloseNodeNewModalAction())
	};
};

const mapStateToProps = (state, props) => {
	const mapped = {};

	const core = state.get("core");
	if (core) {
		mapped.profile = core.get("profile");
	}

	const playground = getNestedState(state, ["canvas", "playground"]);
	if (!playground) {
		return mapped;
	}

	const node = getNestedState(playground, ["inlet", "filter", "canvas", "phase", "node"]);
	if (!node) {
		return mapped;
	}

	return {
		...mapped,
		nodeNew: node.get("new"),
		playgroundDashboard: playground.get("dashboard")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NodeNewModal);
