import {Alert, Chip} from "@mui/material";
import {NumberDescriptor, NumbersDispatchApi} from "./api";
import {TemplateDispatchApi} from "../editors/api";
import {useQuery} from "react-query";
import BigSelector, {BigSelectorItem} from "../../components/common/BigSelector/BigSelector";

import parsePhoneNumber, {CountryCode} from "libphonenumber-js";
import Flags from "country-flag-icons/react/3x2";
import "./NumbersSection.scss";
import React from "react";
import {authenticatedComponent} from "../../common/loader";

type NumberSectionProps = {
    numbersApi: NumbersDispatchApi;
    templateApi: TemplateDispatchApi;
}

function formatPhoneNumber(number: NumberDescriptor): string {

    try {
        const fmt = parsePhoneNumber(number.number, number.country as CountryCode);
        if (fmt) {
            return fmt.formatInternational();
        }
    } catch (e) {
        console.error(e);
    }
    return number.number;
}

function NumberTag({name, value}: { name: string, value: string | JSX.Element }): JSX.Element {

    const inners = <>
        <span className="tag-name">{name}</span>
        <span className="tag-value">{value}</span>
    </>;

    return <Chip className="number-tag" label={inners}/>;
}

function NumbersSectionInternal(props: NumberSectionProps) {

    const {numbersApi, templateApi} = props;

    const {
        isFetching: isFetchingNumbers,
        isError: isErrorFetchingNumbers,
        error: numberFetchingError,
        data: numbers
    } = useQuery("numbers", async () => {
        return await numbersApi.getNumbers();
    });

    if (isErrorFetchingNumbers) {
        console.error(numberFetchingError);
        return <Alert severity="error">Error getting numbers</Alert>;
    }

    if (!numbers) {
        return <Alert severity="error">Error getting numbers</Alert>;
    }

    const items: BigSelectorItem[] = [];

    const countryFlags = {
        "EE": Flags.EE,
        "US": Flags.US,
        "AU": Flags.AU,
        "GB": Flags.GB,
    } as any;

    for (const number of numbers) {

        const tags: JSX.Element[] = [];
        if (number.isSMS) {
            tags.push(<div className="spacer"/>);
            tags.push(<NumberTag name="SMS" value={<i className="fas fa-check-circle"/>}/>);
            tags.push(<div className="spacer"/>);
        }

        if (number.isVoice) {
            tags.push(<div className="spacer"/>);
            tags.push(<NumberTag name="Voice" value={<i className="fas fa-check-circle"/>}/>);
            tags.push(<div className="spacer"/>);
        }

        const Flag = countryFlags[number.country] ?? Flags.AU;
        items.push({
            id: (number.$id || "").toString(),
            name: formatPhoneNumber(number),
            subtitle: number.name,
            icon: <Flag className="flag"/>,
            inlineComponents: tags,
            deleter: {
                onDelete: async (numberId: string) => {
                    //TODO: Delete number
                },
                confirm: {
                    title: "Delete number",
                    message: "Are you sure you want to delete this number?"
                }
            }
        });
    }

    return <BigSelector
        title="Your Numbers"
        emptyMessage="You don't appear to have any numbers yet."
        items={items}
        loading={isFetchingNumbers}
    />;
}

const NumbersSection = authenticatedComponent(NumbersSectionInternal, (token: string) => ({
    numbersApi: new NumbersDispatchApi(token),
    templateApi: new TemplateDispatchApi(token)
}));
export default NumbersSection;
