import React, { Component } from "react";
import PropTypes from "prop-types";

import WindowTaskBar, { TaskBarAction } from "components/common/window/WindowTaskBar";

import Inlets from "./inlet/Inlets";

import "./PlaygroundContent.scss";

const TaskBarPlaySymbol = () => {
	return (
		<div className="playground-start-symbol">
			<div className="start-circle">
        <span className="icon">
          <i className="fas fa-play"/>
        </span>
			</div>
			<div className="start-tab">
				<span>Start</span>
			</div>
		</div>
	);
};

export class PlaygroundContent extends Component {
	render () {
		const { playgroundProps, setFullscreen, isFullscreen } = this.props;
		const {
			dashboard,
			openEditPlayground,
			openPlaygroundDebugger,
			reloadPlayground,
			openEditEnvironment
		} = playgroundProps;
		const { playground } = dashboard;

		const actions = [
			<TaskBarAction key="debug" icon="fas fa-debug" onClick={openPlaygroundDebugger}/>,
			<TaskBarAction key="reload" icon="fas fa-sync-alt" onClick={reloadPlayground}/>,
			<TaskBarAction key="environment" icon="fas fa-layer-group" onClick={openEditEnvironment}/>,
			<TaskBarAction key="settings" icon="fas fa-cog" onClick={openEditPlayground}/>
		];

		return (
			<>
				<WindowTaskBar
					start={[<TaskBarPlaySymbol key="play"/>]}
					title={playground.name}
					actions={actions}
				/>

				<Inlets playgroundProps={playgroundProps}/>
			</>
		);
	}
}

PlaygroundContent.propTypes = {
	playgroundProps: PropTypes.object.isRequired,
	setFullscreen: PropTypes.func,
	isFullscreen: PropTypes.bool
};

export default PlaygroundContent;
