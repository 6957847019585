import jwtDecode from "jwt-decode";

export const RootOrSuperRoles = ["SUPER", "ROOT"];
export const AuthTokenChanged = "AuthTokenChanged"
export const parseToken = (token) => {
	if (token) {
		return jwtDecode(token);
	}
};

export const calculateTokenLocalExpiry = (token) => {
	if (token) {
		const auth = parseToken(token);
		if (auth && auth.expires_in_mins) {
			const date = new Date();
			date.setTime(date.getTime() + auth.expires_in_mins * 1000 * 60);

			return date.getTime();
		}
	}

	return null;
};

export const tokenNeedsRefresh = (token, localExpiryMs) => {
	if (token) {
		const auth = parseToken(token);
		if (auth && auth.refresh_threshold_mins) {
			const date = new Date();

			if (date.getTime() >= localExpiryMs) {
				// Expired
				return -1;
			}

			date.setTime(date.getTime() + auth.refresh_threshold_mins * 1000 * 60);

			if (date.getTime() >= localExpiryMs) {
				// Not Expired, but needs refresh
				return 1;
			}
		}
	}

	return 0;
};

export const setToken = (token) => {

	localStorage.setItem("token", token);
	localStorage.setItem("tokenExpiry", calculateTokenLocalExpiry(token));

	// Cache decoded value
	window.authTokenData = parseToken(token);
	window.dispatchEvent(new Event(AuthTokenChanged));
};

export const getToken = () => {
	return localStorage.getItem("token");
};

export const tokenIsValid = () => {
	if (localStorage.getItem("token")) {
		return !tokenHasExpired(localStorage.getItem("tokenExpiry"));
	}

	return false;
};

export const tokenHasExpired = (localExpiryMs) => {
	const date = new Date();
	return date.getTime() > localExpiryMs;
};

export const clearToken = () => {
	localStorage.removeItem("token");
	localStorage.removeItem("tokenExpiry");

	// Clear decoded value
	window.authTokenData = null;
};

export const setNamespace = (namespace) => {
	localStorage.setItem("namespace", namespace);
};

export const getNamespace = () => {
	return localStorage.getItem("namespace");
};

export const setAccountKey = (accountKey) => {
	localStorage.setItem("accountKey", accountKey);
};

export const getAccountKey = () => {
	return localStorage.getItem("accountKey");
};

export const getAuthTokenData = () => {
	return window.authTokenData;
};

export const authRequestedSuperOrRoot = () => {
	const authTokeData = getAuthTokenData();
	if (!authTokeData || !authTokeData.options) {
		return false;
	}

	const { options } = authTokeData;

	return options.allow_root || options.allow_super;
};

const haveAnyRole = (roles, wantRoles) => {
	const roleMap = {};
	if (roles) {
		roles.forEach(r => {
			roleMap[r] = true;
		});
	}

	let allowed = false;
	wantRoles.forEach(r => {
		if (roleMap[r]) {
			allowed = true;
		}
	});

	return allowed;
};

export const authIsRootForNamespace = () => {
	const namespace = getNamespace();
	const authTokeData = getAuthTokenData();

	if (!namespace || !authTokeData || !authTokeData.namespace_access) {
		return false;
	}

	if (!authTokeData.namespace_access[namespace]) {
		return false;
	}

	return haveAnyRole(authTokeData.namespace_access[namespace].roles,
		["ADMIN", "OWNER"]);
};

export const authHaveAnySuperOrRootRoles = (roles) => {
	return authHaveAnyRoles(RootOrSuperRoles);
};

export const authHaveAnyRoles = (roles) => {
	if (!roles || roles.length < 1) {
		return false;
	}

	const authTokeData = getAuthTokenData();
	if (!authTokeData) {
		return false;
	}

	const roleMap = {};
	if (authTokeData.roles) {
		authTokeData.roles.forEach(r => {
			roleMap[r] = true;
		});
	}

	let allowed = false;
	roles.forEach(r => {
		if (r === "ROOT") {
			if (authIsRootForNamespace()) {
				allowed = true;
			}
		} else {
			if (roleMap[r]) {
				allowed = true;
			}
		}
	});

	return allowed;
};
